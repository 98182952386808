<template>
  <div class="area-wrapper">
    <div class="area_box">
      <div class="buttons">
        <jy-query ref="checkForm" :model="diaform">
          <jy-query-item label="开始时间" prop="startTime" :span="7">
            <el-date-picker
              v-model="diaform.startTime"
              value-format="yyyy-MM-dd HH:mm"
              :picker-options="startTime"
              type="datetime"
              placeholder="选择日期"
              popper-class="no-atTheMoment"
            ></el-date-picker>
          </jy-query-item>
          <jy-query-item label="结束时间" prop="endTime" :span="7">
            <el-date-picker
              v-model="diaform.endTime"
              value-format="yyyy-MM-dd HH:mm"
              :picker-options="endTime"
              type="datetime"
              placeholder="选择日期"
              popper-class="no-atTheMoment"
            ></el-date-picker>
          </jy-query-item>
          <jy-query-item label="选择站点" prop="site">
            <el-select v-model="diaform.site" v-loadmore="loadMore">
              <el-option
                v-for="item in siteList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </jy-query-item>
          <jy-query-item label="设备类型" prop="type">
            <el-select v-model="diaform.type">
              <el-option
                v-for="op in deviceTypeList"
                :key="op.value"
                :label="op.label"
                :value="op.value"
              ></el-option>
            </el-select>
          </jy-query-item>

          <jy-query-item label="回放速度" prop="multiple" v-show="isShow">
            <el-select v-model="diaform.multiple" placeholder="请选择">
              <el-option
                v-for="item in speedList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </jy-query-item>
          <template #rightCol>
            <el-button :disabled="isSearch" type="primary" @click="oncheck"
              >查询</el-button
            >
            <el-button type="primary" @click="toStart" :disabled="isToStart"
              >回放</el-button
            >

            <el-button type="primary" @click="toPause" :disabled="isToPauseStop"
              >暂停</el-button
            >

            <el-button type="primary" @click="toStop" :disabled="isToPauseStop"
              >停止</el-button
            >
          </template>
        </jy-query>
      </div>
      <div class="cars">
        <div class="carTable">
          <jy-table :data="dataList">
            <!-- <jy-table-column type="index" label="序号" width="60"></jy-table-column> -->
            <jy-table-column prop="routeNa" label="线路"></jy-table-column>
            <jy-table-column prop="vehicleNu" label="车牌号"></jy-table-column>
            <jy-table-column prop="time" label="首次经过时间"></jy-table-column>
            <!-- <jy-table-column prop="time" label="末次经过时间"></jy-table-column> -->
            <jy-operate v-bind:list="operationList" width="345"></jy-operate>
          </jy-table>
        </div>
      </div>
      <div class="area-wrapper" ref="container"></div>
    </div>
  </div>
</template>
<script>
import { deviceType } from "@/common/js/wordbook.js";
import gcoord from "gcoord";
import LuShu from "@/common/js/BMapLib/LuShu.js";
import startImg from "@/assets/startIcon.png";
import endImg from "@/assets/endIcon.png";
let trackMap = null,
  lushuInsArray = [];
export default {
  data() {
    return {
      diaform: {
        startTime: "",
        endTime: "",
        latlngs: [],
        type: 4,
        multiple: 10,
        site: ''
      },
      siteIndex: 1,
      pageSize: 10,
      siteList: [],
      //查询位置
      slocation: "",
      locationList: [],
      deviceTypeList: [],
      isToStart: true,
      isToPauseStop: true,
      isSearch: true,
      isShow: false,
      active: 0,
      operationList: [],
      overlays: [],
      dataList: [],
      speedList: [
        {
          value: 10,
          label: "1X",
        },
        {
          value: 30,
          label: "3X",
        },
        {
          value: 50,
          label: "5X",
        },
        {
          value: 100,
          label: "10X",
        }
      ],
      startTime: {
        disabledDate: (time) => {
          if (this.diaform.endTime) {
            return (
              time.getTime() >=
              new Date(this.diaform.endTime).getTime() - 8.64e7
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
    };
  },
  computed: {
    endTime() {
      let hour = "";
      let minu = "";
      let second = "";
      if (this.diaform.startTime != "" && this.diaform.startTime != null) {
        hour = new Date(this.diaform.startTime).getHours();
        minu = new Date(this.diaform.startTime).getMinutes();
        second = new Date(this.diaform.startTime).getSeconds();
      }
      return {
        disabledDate: (time) => {
          if (this.diaform.startTime != "" && this.diaform.startTime != null) {
            if (hour == 0) {
              return (
                time.getTime() > new Date(this.diaform.startTime).getTime() ||
                time.getTime() <
                  new Date(this.diaform.startTime).getTime() - 8.64e7 + 3600000
              );
            } else {
              return (
                (time.getTime() > new Date(this.diaform.startTime).getTime()) |
                (time.getTime() <
                  new Date(this.diaform.startTime).getTime() - 8.64e7)
              );
            }
          } else {
            return time.getTime() > Date.now();
          }
        },
        selectableRange:
          hour +
          ":" +
          minu +
          ":" +
          second +
          "-" +
          (hour + 1) +
          ":" +
          minu +
          ":" +
          second,
      };
    },
  },
  watch: {
    diaform: {
      handler(val) {
        if (
          val.latlngs.length > 0 &&
          val.startTime != "" &&
          val.endTime != ""
        ) {
          this.isSearch = false;
        } else {
          this.isSearch = true;
        }
      },
      deep: true,
    },
    slocation: function () {
      var localSearch = new BMap.LocalSearch(trackMap);
      localSearch.enableAutoViewport(); //允许自动调节窗体大小
      localSearch.setSearchCompleteCallback((searchResult) => {
        if(searchResult) {
          var poi = searchResult.getPoi(0);
          this.lng = poi.point.lng;
          this.lat = poi.point.lat;
          trackMap.centerAndZoom(poi.point, 13);
        }
      });
      localSearch.search(this.slocation);
    },
  },
  mounted() {
    this.diaform.startTime = "2022-04-26 11:00";
    this.diaform.endTime = "2022-04-26 12:00";
    this.deviceTypeList = deviceType();
    this.$nextTick(() => {
      this.initMap();
      this.seOperation();
      this.oncheck();
      this.getSites();
    });
  },
  methods: {
    seOperation() {
      this.operationList = [
        {
          name: "轨迹回放",
          fun: (item) => {
            this.track(item);
          },
          isShow: () => {
            return true;
          },
        },
      ];
    },
    initMap() {
      trackMap = new BMap.Map(this.$refs.container);
      trackMap.enableScrollWheelZoom();
      trackMap.centerAndZoom(new BMap.Point(116.404, 39.915), 13);
    },
    on_click(val) {
      this.active = val;
    },
    getSites() {
      let url = "/site/info/queryPage"
      let option = {
        pageIndex: this.siteIndex,
        pageSize: this.pageSize
      }
      this.$http.post(url, option).then(({ detail }) => {
          let list = detail.list.map(item => {
              return {
                  label: item.cName,
                  value: item.siteId
              }
          })
          this.siteList = list
      })
    },
    loadMore() {
        console.log(111)
        this.siteIndex += 1;
        let option = {
            pageIndex: this.siteIndex,
            pageSize: this.pageSize
        }
        let url = '/site/info/queryPage'
        this.$http.post(url, option).then(({ detail }) => {
            if(detail.list.length > 0) {
                detail.list.map(item => {
                    let list =  {
                        label: item.cName,
                        value: item.siteId
                    }
                    this.siteList.push(list)
                })
            }
        })
    },
    oncheck() {
      this.dataList = [
        {
            vehicleId:"6231b7c9e4b02be9fe7fba79",
            vehicleNu:"浙BD7788D",
            clientId:"014466365701",
            lat:29.863496,
            lng:121.573871,
            time:"2022-04-26 10:46:15",
            routeNa:"901路",
            routeId:"622f1316e4b0594b3b2b4644"
        },
        {
            vehicleId:"6231b7abe4b02be9fe7fba77",
            vehicleNu:"浙B9A739",
            clientId:"014466365703",
            lat:29.871335,
            lng:121.572628,
            time:"2022-04-26 10:36:23",
            routeNa:"901路",
            routeId:"622f1316e4b0594b3b2b4644"
        },
        {
            vehicleId:"6231b78ae4b02be9fe7fba75",
            vehicleNu:"浙B06272D",
            clientId:"014466365705",
            lat:29.866677,
            lng:121.570691,
            time:"2022-04-26 10:00:01","routeNa":"901路",
            routeId:"622f1316e4b0594b3b2b4644"
        }]
    },
    track(item) {
      this.isShow = true;
      trackMap && trackMap.clearOverlays();
      this.isToStart = false;
      this.addLuShu(item);
      this.addSiteMarkers();
      this.addSiteMarkers(item.routeId);
    },
    addSiteMarkers(routeId) {
      this.$http.post("/site/info/queryByRouteId", { routeId }).then((res) => {
        res.detail.forEach((site) => {
          const { routeSite, siteInfo } = site;
          const [lng, lat] = gcoord.transform(
            [siteInfo.lng, siteInfo.lat],
            gcoord.WGS84,
            gcoord.BD09
          );
          const marker = new BMap.Marker(new BMap.Point(lng, lat));
          const labelText =
            siteInfo.cName +
            "(" +
            (routeSite.goType === 1 ? "上行" : "下行") +
            ")";
          let label = new BMap.Label(labelText, {
            offset: new BMap.Size(30, -11),
          });
          marker.setLabel(label);

          trackMap.addOverlay(marker);
        });
      });
    },
    async addLuShu(vehicle) {
      const { detail: deviceList } = await this.$http.post(
        "/base/device/queryByVehicleId",
        {
          vehicleId: vehicle.vehicleId,
        }
      );
      const deviceId = this.getDeviceIdByList(deviceList);
      if (deviceId) {
        const { detail: gpsData } = await this.$http.post(
          "/waybillActual/getGpsDataByDevice",
          {
            deviceNo: deviceId,
            beginTime: this.diaform.startTime,
            endTime: this.diaform.endTime,
          }
        );
        if (Array.isArray(gpsData)) {
          let arrPosi = [],
            landmarkPois = [];
          gpsData.forEach((v) => {
            const [lng, lat] = gcoord.transform(
              [v.lng, v.lat],
              gcoord.WGS84,
              gcoord.BD09
            );
            arrPosi.push(new BMap.Point(lng, lat));
            landmarkPois.push({
              lng: lng,
              lat: lat,
              html: `<div style="text-align:left;">
                                    <span>时间：${this.$util.fixedMacTime(
                                      v.time
                                    )}</span>
                                    <br />
                                    <span>速度：${v.spd}</span>
                                    <br />
                                    <span>行驶里程：${v.mileage}</span>
                                    <br />
                                    <span>经纬度：经度${lng}，纬度${lat}</span>
                                    <br />
                                    <span>方向角：${v.dir}</span>
                                </div>`,
            });
          });

          // 添加polyline
          const polyline = new BMap.Polyline(arrPosi, {
            strokeColor: "blue", //设置颜色
            strokeWeight: 5, //宽度
            strokeOpacity: 0.5, //透明度
          });
          trackMap.addOverlay(polyline);
          // 添加首尾标记
          this.addStartEndMarker(arrPosi[0], arrPosi[arrPosi.length - 1]);

          trackMap.centerAndZoom(arrPosi[0], 16);

          // 添加路书
          const lushu = new LuShu(trackMap, arrPosi, {
            defaultContent: "", //"从天安门到百度大厦"
            autoView: false, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
            icon: new BMap.Icon(
              "https://developer.baidu.com/map/jsdemo/img/car.png",
              new BMap.Size(52, 26),
              { anchor: new BMap.Size(27, 13) }
            ),
            speed: this.diaform.multiple, //运行速度
            enableRotation: true, //是否设置marker随着道路的走向进行旋转
            landmarkPois: landmarkPois,
            showInfoWindow: true,
          });
          lushuInsArray.push(lushu);
          this.isToStart = false;
        }
      }
    },
    getDeviceIdByList(list) {
      if (Array.isArray(list)) {
        const newList = list.filter((v) => v.dType == this.diaform.type);
        return newList[0] ? newList[0].deviceId : "";
      }
    },
    addStartEndMarker(startPos, endPos) {
      if (startPos && endPos) {
        let startPoint = new window.BMap.Point(startPos.lng, startPos.lat);
        let endPoint = new window.BMap.Point(endPos.lng, endPos.lat);
        var startIcon = new BMap.Icon(startImg, new BMap.Size(50, 50));
        var endIcon = new BMap.Icon(endImg, new BMap.Size(50, 50));
        let marker = new BMap.Marker(startPoint, {
          icon: startIcon,
        });
        let endmarker = new BMap.Marker(endPoint, {
          icon: endIcon,
        });
        trackMap.addOverlay(marker);
        trackMap.addOverlay(endmarker);
      }
    },
    toStart() {
      this.isToStart = true;
      this.isToPauseStop = false;
      lushuInsArray.forEach((ins) => {
        ins._opts.speed = this.diaform.multiple; // 修改速度
        ins.start();
      });
    },
    toPause() {
      this.isToStart = false;
      this.isToPauseStop = true;
      lushuInsArray.forEach((ins) => {
        ins.pause();
      });
    },
    toStop() {
      this.isToStart = false;
      this.isToPauseStop = true;
      lushuInsArray.forEach((ins) => {
        ins.stop();
      });
    },
    getlocation(queryString, cb) {
      let url = "/site/info/GetBDMap";
      let option = {
        query: this.slocation,
        region: "全国",
        city_limit: "false",
      };
      this.$http.post(url, option).then((res) => {
        console.log(res);
        this.locationList = res.detail;
        if (this.locationList) {
          this.locationList.forEach((item) => {
            item.value = item.name;
          });
          var results = queryString
            ? this.locationList.filter(this.createFilter(queryString))
            : this.locationList;
          // 调用 callback 返回建议列表的数据
          if (cb && cb instanceof Function) {
            cb(results);
          }
        } else {
          if (cb && cb instanceof Function) {
            cb([]);
          }
        }
      });
    },
    createFilter(queryString) {
      return (state) => {
        return (
          state.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.area-wrapper {
  height: 100%;
  .area_box {
    height: 100%;
    display: flex;
    flex-direction: column;
    .buttons {
      margin-top: 14px;
    }
    .area-wrapper {
      flex: 1;
      height: 100%;
      width: 96%;
      left: 2%;
      min-height: 600px;
      margin-top: 20px;
    }
    .areaform {
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #dcdfe6;
      margin-bottom: 20px;
    }
    .cars {
      margin-top: 20px;
      > p {
        font-size: 18px;
        margin-bottom: 5px;
      }
      .carTable {
        padding: 10px;
        border: 1px solid #dcdfe6;
        > p {
          font-size: 16px;
        }
      }
    }
  }
}
</style>